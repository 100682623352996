import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LensBlurRoundedIcon from '@mui/icons-material/LensBlurRounded';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: '1. Your Authorisation?',
    description:
      'By providing us with your personal information or engaging us to provide you with our services, you consent to the collection, use, storage and disclosure of personal information in accordance with this Privacy Policy.',
  },
  {
    title: '2. Information Collection and Use',
    description:
      'We collect personal information, such as name, address, phone number, email, and financial information from you in order to provide financial advice and services. This information is used to provide personalised financial advice and recommendation in order to meet your financial circumstances and goals.',
  },
  {
    title: '3. Why do we collect your Personal Information?',
    description:
      'In order to provide you with our financial advice and recommendation for products and services that suit you for your circumstances, needs and objectives, we need to collect your personal information. We may also collect your personal information (including credit history and health status) on behalf of the lenders, insurers and other providers of the Products that you may choose to apply for. Product Providers will have their own Privacy Policy that applies to the information that we collect on their behalf. Under no circumstances do we sell your information or share it with third parties unless permitted by you.',
  },
  {
    title: '4. How do we collect your Information?',
    description:
      'We may also collect your personal information from:(a)credit reporting agencies (b) product providers (c) any other person authorised by you. If you provide any personal information about anyone else to us (the Other Person), you confirm that you have collected information in accordance with the Act, therefore, the Other Person has: (a) authorised the disclosure to us and the collection, use and disclosure of its personal information by us in accordance with this Privacy Policy; and (b) been informed of its right to access and request correction of its personal information.',
  },
  {
    title: '5. Information Sharing and Disclosure',
    description:
      'We do not sell, rent, or share any personal information with third parties, except as necessary to provide financial services or as required by law. We may disclose your personal information to the following people if we consider it necessary to do so:',
  },
  {
    title:
      '6. How can you review, update or delete the data we collect from you?',
    description:
      'Based on the applicable laws of our & your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please do so by contacting us.',
  },
];

const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={'h5'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
        }}
      >
        {title}
      </Typography>
      <Typography component={'p'} color={'text.secondary'}>
        {description}
      </Typography>
    </Box>
  );
};

PrivacySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Content = () => {
  return (
    // <Box>
    //   {mock.map((item, i) => (
    //     <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
    //       <PrivacySection {...item} />
    //     </Box>
    //   ))}
    // </Box>
    <Box>
      <Box marginTop={1}>
        <Typography
          variant={'h5'}
          gutterBottom
          sx={{
            fontWeight: 'medium',
          }}
        >
          Privacy Policy
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          At FG Life, we are committed to protecting the privacy of our users.
          This Privacy Policy outlines the information we collect, how we use it
          and the measures we take to ensure its security. References in this
          Privacy Policy to “we”, “us” and “our” means, AIFP Personal Risk Ltd
          (FG Life). We protect your information in accordance with the Privacy
          Act 2020 (the Act).
        </Typography>
      </Box>
      <Box marginTop={1}>
        <Typography
          variant={'h5'}
          gutterBottom
          sx={{
            fontWeight: 'medium',
          }}
        >
          Your Authorisation
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          By providing us with your personal information or engaging us to
          provide you with our services, you consent to the collection, use,
          storage and disclosure of personal information in accordance with this
          Privacy Policy.
        </Typography>
      </Box>
      <Box marginTop={1}>
        <Typography
          variant={'h5'}
          gutterBottom
          sx={{
            fontWeight: 'medium',
          }}
        >
          Information Collection and Use
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          We collect personal information, such as name, address, phone number,
          email, and financial information from you to provide financial advice
          and services. This information is used to provide personalised
          financial advice and recommendation to meet your financial
          circumstances and goals.
        </Typography>
      </Box>
      <Box marginTop={1}>
        <Typography
          variant={'h5'}
          gutterBottom
          sx={{
            fontWeight: 'medium',
          }}
        >
          Why do we collect your Personal Information?
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          To provide you with our financial advice and recommendation for
          products and services that suit you for your circumstances, needs and
          objectives, we need to collect your personal information.
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          We may also collect your personal information (including credit
          history and health status) on behalf of the lenders, insurers and
          other providers of the Products that you may choose to apply for.
          Product Providers will have their own Privacy Policy that applies to
          the information that we collect on their behalf.
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          Under no circumstances do we sell your information or share it with
          third parties unless permitted by you.
        </Typography>
      </Box>

      <Box marginTop={1}>
        <Typography
          variant={'h5'}
          gutterBottom
          sx={{
            fontWeight: 'medium',
          }}
        >
          How do we collect your Information?
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          Generally, we will collect your personal information directly from
          you.
          <br />
          We may also collect your personal information from:
          <List>
            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                with your authorisation, banks and employers;
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>product providers; and</ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>any other person authorised by you.</ListItemText>
            </ListItem>
            <Typography component={'p'} color={'text.secondary'}>
              If you provide any personal information about anyone else to us
              (the Other Person), you confirm that you have collected
              information in accordance with the Act, therefore, the Other
              Person has:
            </Typography>
            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                authorised the disclosure to us and the collection, use and
                disclosure of its personal information by us in accordance with
                this Privacy Policy; and
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                been informed of its right to access and request correction of
                its personal information.
              </ListItemText>
            </ListItem>
          </List>
        </Typography>
      </Box>
      <Box marginTop={1}>
        <Typography
          variant={'h5'}
          gutterBottom
          sx={{
            fontWeight: 'medium',
          }}
        >
          Information Sharing and Disclosure
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          We do not sell, rent, or share any personal information with third
          parties, except as necessary to provide financial services or as
          required by law. We may disclose your personal information to the
          following people if we consider it necessary to do so:
          <List>
            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                Product Providers, third parties or others.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                Referral partners who can assist you with your circumstances and
                goals.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                Investors or any entity that has an interest in our business or
                any entity to whom we consider assigning or transferring any of
                our rights or obligations or selling all or part of our
                business.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                If we are legally required to share your information, such as
                regulatory bodies and the Government.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                To auditors to ensure that the services we provided to you are
                in your best interests.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                Your employer and referees, as well as credit reporting and
                identity verification agencies.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <LensBlurRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                Any other person or entity authorised by you.
              </ListItemText>
            </ListItem>
          </List>
          <Typography component={'p'} color={'text.secondary'}>
            Prior to disclosing any of your personal information to any other
            persons or organisations, we will take all reasonable steps to make
            sure that are compliant with the Act and that the person or
            organisation has also committed to protecting your personal
            information at least equal to our commitment.
          </Typography>
        </Typography>
      </Box>

      <Box marginTop={1}>
        <Typography
          variant={'h5'}
          gutterBottom
          sx={{
            fontWeight: 'medium',
          }}
        >
          Do we disclose your Personal Information to anyone outside New
          Zealand?
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          We may use cloud storage to store your personal information. The cloud
          storage and the data centres may be located outside New Zealand.
          <br />
          <br />
          To provide you with our services, we may need to share your
          information with organisations outside New Zealand, for example,
          information technology providers based in India or Australia.
        </Typography>
      </Box>
      <Box marginTop={1}>
        <Typography
          variant={'h5'}
          gutterBottom
          sx={{
            fontWeight: 'medium',
          }}
        >
          Your Rights
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          You may access and request corrections for any of your personal
          information that we held at any time.
          <br />
          <br />
          We will generally rely on you to ensure the information we held about
          you is accurate. If any of your personal information changes, please
          contact us as soon as practicable via email info@fglife.co.nz
        </Typography>
      </Box>
      <Box marginTop={1}>
        <Typography
          variant={'h5'}
          gutterBottom
          sx={{
            fontWeight: 'medium',
          }}
        >
          Security
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          We take the security of our users' personal information seriously and
          use appropriate physical, technical and administrative safeguards to
          protect it from unauthorised access, use, or disclosure. This includes
          encryption, secure servers, and firewalls.
        </Typography>
      </Box>
      <Box marginTop={1}>
        <Typography
          variant={'h5'}
          gutterBottom
          sx={{
            fontWeight: 'medium',
          }}
        >
          Updates to Privacy Policy
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or applicable laws. We encourage you to review this
          policy periodically for any updates.
        </Typography>
      </Box>
      <Box marginTop={1}>
        <Typography
          variant={'h5'}
          gutterBottom
          sx={{
            fontWeight: 'medium',
          }}
        >
          Contact Information
        </Typography>
        <Typography component={'p'} color={'text.secondary'}>
          If you have any questions about this Privacy Policy or our information
          practices, please contact our Privacy Officer using the details
          provided below:
          <br />
          <br />
          <Typography
            variant={'h5'}
            gutterBottom
            sx={{
              fontWeight: 'medium',
            }}
          >
            Phone : 0800 344 700
          </Typography>
          <Typography
            variant={'h5'}
            gutterBottom
            sx={{
              fontWeight: 'medium',
            }}
          >
            Email : compliance@fglife.co.nz
          </Typography>
          <Typography
            variant={'h5'}
            gutterBottom
            sx={{
              fontWeight: 'medium',
            }}
          >
            Post : 430 Ulster Street, Beerescourt, Hamilton 3200
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default Content;
